import { graphql, navigate } from "gatsby";
import PropTypes from "prop-types";
import React, { useLayoutEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import { Sliders } from "../components";
import { LocaleContext } from "../components/Layout";
import SEO from "../components/SEO";

const Index = ({ data: { slidesData }, pageContext: { locale }, location }) => {
  const lang = React.useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];

  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);

      window.addEventListener("resize", () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      });

      if (window.innerWidth < 899) {
        i18n && i18n.path === "en"
          ? navigate("/en/phase-two")
          : navigate("/phase-deux");
      }

      function handleResize() {
        i18n && i18n.path === "en"
          ? navigate("/en/phase-two")
          : navigate("/phase-deux");
        return window.location.reload();
      }

      if (window.innerWidth > 899) {
        window.addEventListener("resize", handleResize);
      }

      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return (
    <>
      <SEO pathname={location.pathname} locale={locale} />

      {(browserName === "Safari" && browserVersion < 12) ||
      browserName === "IE" ||
      (browserName === "isEdge" && browserVersion < 30) ? (
        <h2
          style={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -40%)",
            textAlign: "center",
          }}
        >
          {" "}
          Your current web browser version is not supported. Please download the
          latest Chrome/Opera/Firefox for a better user experience
        </h2>
      ) : (
        <Sliders slidesData={slidesData.edges} />
      )}
    </>
  );
};

export default Index;

Index.propTypes = {
  data: PropTypes.shape({
    slidesData: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query IndexQuery($locale: String!) {
    slidesData: allPrismicSlide(
      sort: { fields: data___page_number, order: ASC }
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          data {
            page_number
            all_slide_background_color
            title {
              raw {
                type
                text
              }
            }
            order
            body {
              ... on PrismicSlideBodyPage {
                id
                items {
                  position
                  image_fit
                  padding_image
                  surlignage
                  image_animation_select
                  texte_animation_select
                  extra_animation_select
                  image_animation_duration
                  image_animation_delay
                  text_animation_duration
                  text_animation_delay
                  extra_animation_duration
                  extra_animation_delay
                  text_only_on_mobile
                  background_color
                  image {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1600, quality: 50) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                    mobile {
                      alt
                      dimensions {
                        height
                        width
                      }
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 899) {
                            ...GatsbyImageSharpFluid_noBase64
                          }
                        }
                      }
                    }
                  }
                  text_image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1600, quality: 50) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }

                    mobile {
                      alt
                      dimensions {
                        height
                        width
                      }
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 899) {
                            ...GatsbyImageSharpFluid_noBase64
                          }
                        }
                      }
                    }
                  }

                  extra_image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1600, quality: 50) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                    mobile {
                      alt
                      dimensions {
                        height
                        width
                      }
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 899) {
                            ...GatsbyImageSharpFluid_noBase64
                          }
                        }
                      }
                    }
                  }
                  textarea_color
                  textarea {
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
