import PropTypes from "prop-types";
import React, { useLayoutEffect, useState } from "react";
import "../../styles/mobile.css";
import SlideShow from "./SlideShow";

const Slide = ({ node, info, index }) => {
  const [checkedValue, setCheckValue] = useState(false);
  const numOfSlides = info.length;
  const [, setWidth] = useState("");
  const [, setHeight] = useState("");

  const pageNumber = node.data.page_number && node.data.page_number;

  function handleClick() {
    setCheckValue(true);
  }

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  });

  return (
    <>
      <input
        className="accordion-select"
        type="radio"
        name="select"
        defaultChecked={index === 0 && true}
        onClick={() => handleClick()}
        aria-expanded={checkedValue}
        aria-controls={`slide ${index + 1} - ${node.data.title.raw[0].text}`}
      />

      <div className="accordion-title">
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "4rem",
          }}
        >
          {node.data.title.raw[0].text}
        </span>
        <div className="page-number">
          {pageNumber > 0 && (pageNumber < 10 ? "0" : "") + pageNumber}
        </div>
      </div>

      <div
        className="accordion-content"
        id={`accordion-${index}`}
        aria-hidden={!checkedValue}
        /*         style={{
          backgroundColor: node.data.all_slide_background_color,
        }} */
      >
        {index === 0 ? (
          /* Homepage */
          <SlideShow node={node} />
        ) : index === 1 ? (
          /* Projet */
          <SlideShow
            node={node}
            index={index}
            numOfSlides={numOfSlides}
            status={checkedValue}
          />
        ) : index === 2 ? (
          /* Architecture */
          <SlideShow node={node} index={index} status={checkedValue} />
        ) : index === 3 ? (
          /* Unités */
          <SlideShow node={node} index={index} status={checkedValue} />
        ) : index === 4 ? (
          /* Aires  de vie */
          <SlideShow node={node} index={index} status={checkedValue} />
        ) : index === 5 ? (
          /* Le Quartier */
          <SlideShow node={node} index={index} status={checkedValue} />
        ) : index === 6 ? (
          /* Partenaires */
          <SlideShow node={node} index={index} status={checkedValue} />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Slide;

Slide.propTypes = {
  node: PropTypes.object.isRequired,
};
