import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, { useLayoutEffect, useState } from "react";
import Slide from "./Slide";
import { use100vh } from "react-div-100vh";

const Accordion = styled.div`
  box-sizing: border-box;
  display: flex;
  font-family: "'Agatho ligth', Helvetica, sans-serif";
  overflow: hidden;
  width: 100%;
  /*  border-color: #f3e4da; */
  border-radius: 0px;
  border-style: solid;
  border-width: 0px;
  flex-direction: row;
  height: 92.9vh;
  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    flex-direction: column;
    height: auto;
  }
`;

const Slides = ({ slidesData }) => {
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");

  const heightIpad = use100vh();
  const ModfHeight = heightIpad ? heightIpad - 60 : "92.9vh";

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  });

  return width && width > 899 ? (
    <Accordion style={{ height: `${ModfHeight}px` }}>
      {slidesData &&
        slidesData.map((slideData, index) => {
          return (
            <Slide
              key={index}
              node={slideData.node}
              info={slidesData}
              index={index}
            />
          );
        })}
    </Accordion>
  ) : (
    ""
  );
};
export default Slides;

Slides.propTypes = {
  slidesData: PropTypes.array.isRequired,
};
