import React from "react";
import styled from "@emotion/styled";
import { reset, theme } from "../../../styles";

const Container = styled.div`
  height: calc(100vh - 6vh);
  overflow: hidden !important;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2,
  h3 {
    font-style: italic;
  }

  a {
    font-size: 1.2rem;
    color: ${theme.colors.bg};
  }

  p {
    margin: 1rem;
  }

  img {
    margin: 0 auto;
    display: flex;
  }
`;

const TextDesktop = styled.div`
  overflow: hidden !important;
  font-size: 90%;
  /*   padding: 1rem 5rem; */
  line-height: 1;
  box-sizing: border-box;
  @media (max-width: 1600px) {
    font-size: 70%;
    padding: 1rem 1rem;
  }
`;

const ClickToText = ({ text }) => {
  return (
    <Container>
      <TextDesktop dangerouslySetInnerHTML={{ __html: text.html }} />
    </Container>
  );
};

export default ClickToText;
