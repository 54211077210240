import React from "react";
import SingleColumn from "./templates/SingleColumn";
import TwoColumns from "./templates/TwoColumns";

const ColumnDispatch = ({
  node,
  visibility,
  index,
  width,
  height,
  SwiperState,
}) => {
  const nodeBodyChecker = node && node.data && node.data.body;

  const column = (page, colNum) => {
    return (
      nodeBodyChecker[page] &&
      nodeBodyChecker[page].items[colNum] &&
      nodeBodyChecker[page].items[colNum]
    );
  };

  const colLeft = column(index, 0) ? column(index, 0) : "";
  const colRight = column(index, 1) ? column(index, 1) : "";

  const cmsValues = {
    width,
    height,
    node,
    colLeft: column(index, 0) ? column(index, 0) : "",
    colRight: column(index, 1) ? column(index, 1) : "",
    imgLeftValidation:
      colLeft.image &&
      colLeft.image.localFile &&
      colLeft.image.localFile.childImageSharp,

    imgRightValidation:
      colRight.image &&
      colRight.image.localFile &&
      colRight.image.localFile.childImageSharp,

    imgTextLeftValidation:
      colLeft.text_image &&
      colLeft.text_image.localFile &&
      colLeft.text_image.localFile.childImageSharp,

    imgTextRightValidation:
      colRight.text_image &&
      colRight.text_image.localFile &&
      colRight.text_image.localFile.childImageSharp,

    imgExtraTextLeftValidation:
      colLeft.extra_image &&
      colLeft.extra_image.localFile &&
      colLeft.extra_image.localFile.childImageSharp,

    imgExtraTextRightValidation:
      colRight.extra_image &&
      colRight.extra_image.localFile &&
      colRight.extra_image.localFile.childImageSharp,

    altImageLeft: colLeft.image && colLeft.image.alt,
    altTextImageLeft: colLeft.image && colLeft.text_image.alt,
    altExtraImageLeft: colLeft.image && colLeft.extra_image.alt,

    altImageRight: colRight.image && colRight.image.alt,
    altTextImageRight: colRight.image && colRight.text_image.alt,
    altExtraImageRight: colRight.image && colRight.extra_image.alt,

    imageAnimationOptionLeft: colLeft.image_animation_select,
    imageAnimationOptionRight: colRight.image_animation_select,
    imageAnimationDurationLeft: colLeft.image_animation_duration,
    imageAnimationDurationRight: colRight.image_animation_duration,

    textAnimationOptionLeft: colLeft.texte_animation_select,
    textAnimationOptionRight: colRight.texte_animation_select,
    textAnimationDurationLeft: colLeft.text_animation_duration,
    textAnimationDurationRight: colRight.text_animation_duration,

    extraAnimationOptionLeft: colLeft.extra_animation_select,
    extraAnimationOptionRight: colRight.extra_animation_select,
    extraAnimationDurationLeft: colLeft.extra_animation_duration,
    extraAnimationDurationRight: colRight.extra_animation_duration,
    extraAnimationDelayLeft: colLeft.extra_animation_delay,
    extraAnimationDelayRight: colRight.extra_animation_delay,

    imageAnimationDelayLeft: colLeft.image_animation_delay,
    imageAnimationDelayRight: colRight.image_animation_delay,

    textAnimationDelayLeft: colLeft.text_animation_delay,
    textAnimationDelayRight: colRight.text_animation_delay,

    imgFitLeft: colLeft && colLeft.image_fit !== null ? colLeft.image_fit : 0,
    imgFitRight:
      colRight && colRight.image_fit !== null ? colRight.image_fit : 0,

    positionLeft:
      colLeft && colLeft.position !== null ? colLeft.position : "center",
    positionRight:
      colRight && colRight.position !== null ? colRight.position : "center",

    paddingValueLeft:
      colLeft && colLeft.padding_image !== null ? colLeft.padding_image : 0,
    paddingValueRight:
      colRight && colRight.padding_image !== null ? colRight.padding_image : 0,

    surlignageLeft:
      colLeft && colLeft.surlignage !== null ? colLeft.surlignage : "",
    surlignageRight:
      colRight && colRight.surlignage !== null ? colRight.surlignage : "",

    visibility: visibility,
    textAreaLeft: colLeft.textarea && colLeft.textarea,
    textAreaRight: colRight.textarea && colRight.textarea,

    textAreaColorLeft:
      colLeft && colLeft.textarea_color && colLeft.textarea_color,
    textAreaColorRight:
      colRight && colRight.textarea_color && colRight.textarea_color,

    textOnlyOnMobileLeft: colLeft && colLeft.text_only_on_mobile,
    textOnlyOnMobileRight: colRight && colRight.text_only_on_mobile,

    textAreaColorLeft:
      colLeft && colLeft.textarea_color && colLeft.textarea_color,
    textAreaColorRight:
      colRight && colRight.textarea_color && colRight.textarea_color,
    animationSwitcher: function animationSwitcher(columnValue) {
      switch (columnValue) {
        case "Left":
          return !SwiperState.includes(parseInt(index))
            ? animationLeft
            : animationNone;
        case "Right":
          return !SwiperState.includes(parseInt(index))
            ? animationRight
            : animationNone;
        case "Fade in":
          return !SwiperState.includes(parseInt(index))
            ? animationFadeIn
            : animationNone;
        case "Top":
          return !SwiperState.includes(parseInt(index))
            ? animationTop
            : animationNone;
        case "Bottom":
          return !SwiperState.includes(parseInt(index))
            ? animationBottom
            : animationNone;
        case "None":
          return "";
        default:
          return "animationLeft";
      }
    },
    animationLeft: {
      initial: { opacity: 0, x: 140 },
      start: { opacity: 1, x: 0 },
      exit: { opacity: 0 },
    },

    animationRight: {
      initial: { opacity: 0, x: -140 },
      start: { opacity: 1, x: 0 },
      exit: { opacity: 0 },
    },

    animationFadeIn: {
      initial: { opacity: 0 },
      start: { opacity: 1 },
      exit: { opacity: 0 },
    },

    animationTop: {
      initial: { opacity: 0, y: -140 },
      start: { opacity: 1, y: 0 },
      exit: { opacity: 0 },
    },

    animationBottom: {
      initial: { opacity: 0, y: 140 },
      start: { opacity: 1, y: 0 },
      exit: { opacity: 0 },
    },

    animationNone: {
      initial: { opacity: 1 },
      start: { opacity: 1 },
      exit: { opacity: 1 },
    },

    easing: "easeOut",

    SwiperState,
  };

  const {
    animationLeft,
    animationRight,
    animationFadeIn,
    animationTop,
    animationBottom,
    animationNone,
  } = cmsValues;

  return (
    <>
      {nodeBodyChecker[index].items.length > 1 ? (
        <TwoColumns {...cmsValues} />
      ) : (
        <SingleColumn {...cmsValues} />
      )}
    </>
  );
};

export default ColumnDispatch;
