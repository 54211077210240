import { motion, AnimatePresence } from "framer-motion";
import Img from "gatsby-image";
import React, { useState } from "react";
import ClickToText from "./ClickToText";

const TwoColumns = ({
  width,
  height,
  node,
  visibility,
  colLeft,
  colRight,
  animationSwitcher,

  positionLeft,
  positionRight,
  imgFitLeft,
  imgFitRight,
  paddingValueLeft,
  paddingValueRight,
  imgRightValidation,
  imageAnimationOptionLeft,
  textAnimationOptionLeft,
  textAnimationDurationLeft,
  textAnimationDelayLeft,
  altImageLeft,
  altTextImageLeft,
  altExtraImageLeft,
  altImageRight,
  altTextImageRight,
  altExtraImageRight,

  imgExtraTextLeftValidation,
  imgExtraTextRightValidation,
  extraAnimationOptionLeft,
  extraAnimationOptionRight,
  extraAnimationDurationLeft,
  extraAnimationDurationRight,
  extraAnimationDelayLeft,
  extraAnimationDelayRight,

  imageAnimationDurationLeft,
  imageAnimationOptionRight,
  textAnimationDurationRight,
  textAnimationDelayRight,
  imageAnimationDelayRight,
  textAnimationOptionRight,
  imageAnimationDurationRight,
  imageAnimationDelayLeft,
  imgLeftValidation,

  imgTextRightValidation,

  textAreaLeft,
  textAreaRight,
  textAreaColorLeft,
  textAreaColorRight,

  textOnlyOnMobileLeft,
  textOnlyOnMobileRight,

  easing,
}) => {
  const [ClickToTextStatusLeft, setClickToTextStatusLeft] = useState(false);
  const [ClickToTextStatusRight, setClickToTextStatusRight] = useState(false);

  const handleClickLeft = () => {
    if (textAreaLeft && textAreaLeft.html && textAreaLeft.html.length > 9) {
      setClickToTextStatusLeft(!ClickToTextStatusLeft);
    }
  };

  const handleClickRight = () => {
    if (textAreaRight && textAreaRight.html && textAreaRight.html.length > 9) {
      setClickToTextStatusRight(!ClickToTextStatusRight);
    }
  };

  return (
    <>
      {ClickToTextStatusLeft && (
        <div
          className="img-container__left"
          style={{
            color: textAreaColorLeft
              ? textAreaColorLeft
              : colLeft.background_color,
          }}
          onClick={handleClickLeft}
        >
          <ClickToText text={textAreaLeft} />
        </div>
      )}
      <div
        className="img-container__left"
        style={{
          backgroundColor: colLeft.background_color,
          flexGrow: 1,
          display: ClickToTextStatusLeft && "none",
          cursor:
            textAreaLeft &&
            textAreaLeft.html &&
            textAreaLeft.html.length > 9 &&
            "pointer",
        }}
        onClick={handleClickLeft}
      >
        <div
          style={{
            flexGrow: 1,
            display: "block",
          }}
          className="img-first"
        >
          {/* Image 1 Left */}
          <AnimatePresence exitBeforeEnter>
            {imgLeftValidation && visibility && (
              <motion.div
                className="image-first__left"
                variants={
                  visibility && animationSwitcher(imageAnimationOptionLeft)
                }
                initial="initial"
                animate="start"
                exit="exit"
                transition={{
                  duration: imageAnimationDurationLeft
                    ? parseInt(imageAnimationDurationLeft)
                    : 1.4,
                  delay: imageAnimationDelayLeft
                    ? parseInt(imageAnimationDelayLeft)
                    : 0.6,

                  ease: easing,
                }}
              >
                <Img
                  imgStyle={{
                    padding: paddingValueLeft
                      ? `${paddingValueLeft}rem`
                      : "0rem",
                    objectFit: imgFitLeft ? "cover" : "contain",
                    objectPosition: positionLeft ? positionLeft : "center",
                    /*   willChange: "transform", */
                    boxSizing: "border-box",
                  }}
                  loading="eager"
                  fadeIn={false}
                  fluid={
                    node.data && colLeft.image.localFile.childImageSharp.fluid
                  }
                  alt={altImageLeft}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Text Image  Left */}
        <div className="image-text__left">
          <AnimatePresence exitBeforeEnter>
            {visibility && altTextImageLeft !== "null" && (
              <motion.div
                variants={
                  visibility && animationSwitcher(textAnimationOptionLeft)
                }
                initial="initial"
                animate="start"
                exit="exit"
                transition={{
                  duration: textAnimationDurationLeft
                    ? parseInt(textAnimationDurationLeft)
                    : 1.2,
                  delay: textAnimationDelayLeft
                    ? parseInt(textAnimationDelayLeft)
                    : 1.2,
                  ease: easing,
                }}
                style={{
                  flexGrow: 1,

                  display: "block",
                }}
                className="image-test image2"
              >
                <div
                  style={{
                    flexGrow: 1,
                    /*                   padding:
                    width >= 1920 && height <= 1000 && paddingValueLeft
                      ? `${paddingValueLeft}rem`
                      : 0, */
                    display: "block",
                    boxSizing: "border-box",
                  }}
                >
                  {colLeft.text_image.localFile && (
                    <Img
                      imgStyle={{
                        padding: paddingValueLeft
                          ? `${paddingValueLeft}rem`
                          : "0rem",
                        objectFit: imgFitLeft ? "cover" : "contain",
                        objectPosition: positionLeft ? positionLeft : "center",
                        /*   willChange: "transform", */
                        boxSizing: "border-box",
                      }}
                      fadeIn={false}
                      loading="lazy"
                      fluid={colLeft.text_image.localFile.childImageSharp.fluid}
                      alt={altTextImageLeft}
                    />
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {/*Extra Image  Left*/}
        <div className="image-text__left">
          <AnimatePresence exitBeforeEnter>
            {visibility && altExtraImageLeft !== "null" && (
              <motion.div
                variants={
                  visibility && animationSwitcher(extraAnimationOptionLeft)
                }
                initial="initial"
                animate="start"
                exit="exit"
                transition={{
                  duration: extraAnimationDurationLeft
                    ? parseInt(extraAnimationDurationLeft)
                    : 1.2,
                  delay: extraAnimationDelayLeft
                    ? parseInt(extraAnimationDelayLeft)
                    : 1.2,
                  ease: easing,
                }}
                style={{
                  flexGrow: 1,

                  display: "block",
                }}
                className="image-test image2"
              >
                <div
                  style={{
                    flexGrow: 1,
                    /*                   padding:
                    width >= 1920 && height <= 1000 && paddingValueRight
                      ? paddingValueRight + "rem"
                      : 0, */
                    display: "block",
                    boxSizing: "border-box",
                  }}
                >
                  {imgExtraTextLeftValidation && (
                    <Img
                      imgStyle={{
                        padding: paddingValueLeft
                          ? `${paddingValueLeft}rem`
                          : "0rem",
                        objectFit: imgFitLeft ? "cover" : "contain",
                        objectPosition: positionLeft ? positionLeft : "center",
                        /*   willChange: "transform", */
                        boxSizing: "border-box",
                      }}
                      fadeIn={false}
                      loading="lazy"
                      fluid={imgExtraTextLeftValidation.fluid}
                      alt={altExtraImageLeft}
                    />
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      {ClickToTextStatusRight && (
        <div
          className="img-container__right"
          style={{
            color: textAreaColorRight
              ? textAreaColorRight
              : colLeft.background_color,
          }}
          onClick={handleClickRight}
        >
          <ClickToText text={textAreaRight} />
        </div>
      )}
      {imgRightValidation && (
        <div
          className="img-container__right"
          style={{
            display: ClickToTextStatusRight && "none",
            backgroundColor: colRight.background_color,
            flexGrow: 1,
            cursor:
              textAreaRight &&
              textAreaRight.html &&
              textAreaRight.html.length > 9 &&
              "pointer",
          }}
          onClick={handleClickRight}
        >
          {/* Image  Right */}
          <div
            className="image-first"
            style={{
              flexGrow: 1,

              display: "block",
            }}
          >
            <AnimatePresence exitBeforeEnter>
              {imgRightValidation && visibility && (
                <motion.div
                  variants={
                    visibility && animationSwitcher(imageAnimationOptionRight)
                  }
                  initial="initial"
                  animate="start"
                  exit="exit"
                  transition={{
                    duration: imageAnimationDurationRight
                      ? parseInt(imageAnimationDurationRight)
                      : 1.4,
                    delay: imageAnimationDelayRight
                      ? parseInt(imageAnimationDelayRight)
                      : 0.6,
                    ease: easing,
                  }}
                >
                  <Img
                    imgStyle={{
                      padding: paddingValueRight
                        ? `${paddingValueRight}rem`
                        : "0rem",
                      boxSizing: "border-box",
                      objectFit: imgFitRight ? "cover" : "contain",
                      objectPosition: positionRight ? positionRight : "center",
                      /*   willChange: "transform", */
                    }}
                    className="image-test image2"
                    fadeIn={false}
                    loading="eager"
                    fluid={colRight.image.localFile.childImageSharp.fluid}
                    alt={altImageRight}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* Text Image  Right */}
          <div className="image-text__right">
            <div
              style={{
                flexGrow: 1,
                /*                 padding:
                  width >= 1920 && height <= 1000 && paddingValueRight
                    ? paddingValueRight + "rem"
                    : 0, */
                display: "block",
                boxSizing: "border-box",
              }}
            >
              <AnimatePresence exitBeforeEnter>
                {imgTextRightValidation &&
                  visibility &&
                  altTextImageRight !== "null" && (
                    <motion.div
                      variants={
                        visibility &&
                        animationSwitcher(textAnimationOptionRight)
                      }
                      initial="initial"
                      animate="start"
                      exit="exit"
                      transition={{
                        duration: textAnimationDurationRight
                          ? parseInt(textAnimationDurationRight)
                          : 1.2,
                        delay: textAnimationDelayRight
                          ? parseInt(textAnimationDelayRight)
                          : 1.2,
                        ease: easing,
                      }}
                    >
                      <Img
                        imgStyle={{
                          padding: paddingValueRight
                            ? `${paddingValueRight}rem`
                            : "0rem",
                          boxSizing: "border-box",
                          objectFit: imgFitRight ? "cover" : "contain",
                          objectPosition: positionRight
                            ? positionRight
                            : "center",
                          /*   willChange: "transform", */
                        }}
                        fadeIn={false}
                        loading="lazy"
                        fluid={imgTextRightValidation.fluid}
                        alt={altTextImageRight}
                      />
                    </motion.div>
                  )}
              </AnimatePresence>
            </div>
          </div>
          {/*Extra Image  Right*/}
          <div className="image-text__right">
            <AnimatePresence exitBeforeEnter>
              {visibility && altExtraImageRight !== "null" && (
                <motion.div
                  variants={
                    visibility && animationSwitcher(extraAnimationOptionRight)
                  }
                  initial="initial"
                  animate="start"
                  exit="exit"
                  transition={{
                    duration: extraAnimationDurationRight
                      ? parseInt(extraAnimationDurationRight)
                      : 1.2,
                    delay: extraAnimationDelayRight
                      ? parseInt(extraAnimationDelayRight)
                      : 1.2,
                    ease: easing,
                  }}
                  style={{
                    flexGrow: 1,

                    display: "block",
                  }}
                  className="image-test image2"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      /*                   padding:
                      width >= 1920 && height <= 1000 && paddingValueRight
                        ? paddingValueRight + "rem"
                        : 0, */
                      display: "block",
                      boxSizing: "border-box",
                    }}
                  >
                    {imgExtraTextRightValidation && (
                      <Img
                        imgStyle={{
                          padding: paddingValueRight
                            ? `${paddingValueRight}rem`
                            : "0rem",
                          boxSizing: "border-box",
                          objectFit: imgFitRight ? "cover" : "contain",
                          objectPosition: positionRight
                            ? positionRight
                            : "center",
                          /*   willChange: "transform", */
                        }}
                        fadeIn={false}
                        loading="lazy"
                        fluid={imgExtraTextRightValidation.fluid}
                        alt={altExtraImageRight}
                      />
                    )}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      )}{" "}
    </>
  );
};

export default TwoColumns;
