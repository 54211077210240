/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */

import React, { useEffect, useState, useRef } from "react";
import SwiperCore, {
  A11y,
  Lazy,
  Mousewheel,
  Navigation,
  Pagination,
  Parallax,
  Virtual,
  EffectFade,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "../../styles/mobile.css";
import ColumnDispatch from "./ColumnDispatch";

// install Swiper components
SwiperCore.use([
  Navigation,
  Pagination,
  A11y,
  Mousewheel,
  Parallax,
  Lazy,
  Virtual,
  EffectFade,
]);

const SlideShow = ({ node, status }) => {
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [swiperIndex, setSwiperIndex] = useState("");

  const swiperRef = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);

      /*  */
    }
  });

  const numberOfPages =
    node && node.data && node.data.body && node.data.body.length;

  const pageNumber =
    node.data.page_number && node.data.page_number && node.data.page_number;

  return (
    <>
      <Swiper
        ref={swiperRef}
        style={{
          backgroundColor:
            node.data && node.data.all_slide_background_color
              ? node.data.all_slide_background_color
              : "rgb(249, 242, 237)",
        }}
        spaceBetween={0}
        autoHeight={false}
        effect="slide"
        slidesPerView={1}
        pagination={{
          clickable: true,
          renderBullet(index, className) {
            return `<span class=${className}>&bullet;</span>`;
          },

          /*  ${index +
            pageNumber} */
        }}
        lazy
        a11y
        virtual
        speed={1200}
        watchSlidesVisibility
        navigation
        mousewheel
        watchOverflow={false}
        init
        /*Set slide animation state*/
        onSlideChangeTransitionStart={() => {
          const swiperInstance = swiperRef.current.swiper;
          setSwiperIndex((prevArray) => [
            ...prevArray,
            0,
            swiperInstance.activeIndex,
          ]);
        }}
      >
        {Array.from({ length: numberOfPages }).map((i, el) => {
          return (
            <SwiperSlide
              className="swiper-slide swiper-lazy"
              virtualIndex={el}
              key={el}
            >
              {({ isActive, isVisible }) => (
                <div
                  className={"page-container"}
                  style={{
                    background:
                      node.data &&
                      node.data.body[el] &&
                      node.data.body[el].items &&
                      node.data.body[el].items[0] &&
                      node.data.body[el].items[0].background_color &&
                      node.data.body[el].items[0].background_color,
                  }}
                >
                  <ColumnDispatch
                    node={node}
                    visibility={
                      status && isVisible
                        ? isActive
                        : status === undefined && isVisible
                        ? isActive
                        : null
                    }
                    width={width && width}
                    height={height && height}
                    index={el}
                    SwiperState={swiperIndex}
                  />
                </div>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default SlideShow;
