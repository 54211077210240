import { motion, AnimatePresence } from "framer-motion";
import Img from "gatsby-image";
import React from "react";

const SingleColumn = ({
  width,
  height,
  node,
  visibility,
  colLeft,
  animationSwitcher,
  imgFitLeft,
  paddingValueLeft,
  imageAnimationOptionLeft,
  textAnimationOptionLeft,
  textAnimationDurationLeft,
  textAnimationDelayLeft,
  imageAnimationDurationLeft,
  imageAnimationDelayLeft,
  imgLeftValidation,
  altImageLeft,
  altTextImageLeft,
  altExtraImageLeft,
  imgExtraTextLeftValidation,
  extraAnimationOptionLeft,
  extraAnimationDurationLeft,
  extraAnimationDelayLeft,
  textAreaLeft,
  textOnlyOnMobileLeft,
  textAreaColorLeft,
  easing,
}) => {
  return (
    <>
      {textOnlyOnMobileLeft || textOnlyOnMobileLeft === null ? (
        <div
          className="img-container__center"
          style={{
            backgroundColor: colLeft.background_color,
            flexGrow: 1,
          }}
        >
          <div
            style={{
              flexGrow: 1,
              display: "block",
            }}
          >
            {/*Image 1 Left*/}
            <AnimatePresence exitBeforeEnter>
              {imgLeftValidation && visibility && (
                <motion.div
                  className="img-first"
                  variants={
                    visibility && animationSwitcher(imageAnimationOptionLeft)
                  }
                  initial="initial"
                  animate="start"
                  exit={{ opacity: 1 }}
                  transition={{
                    duration: imageAnimationDurationLeft
                      ? parseInt(imageAnimationDurationLeft)
                      : 1.4,
                    delay: imageAnimationDelayLeft
                      ? parseInt(imageAnimationDelayLeft)
                      : 0.6,

                    ease: easing,
                  }}
                >
                  <Img
                    imgStyle={{
                      padding: paddingValueLeft
                        ? `${paddingValueLeft}rem`
                        : "0rem",

                      objectFit: imgFitLeft ? "cover" : "contain",
                      /*    willChange: "transform", */
                      boxSizing: "border-box",
                    }}
                    fadeIn={false}
                    loading="eager"
                    fluid={
                      node.data && colLeft.image.localFile.childImageSharp.fluid
                    }
                    alt={altImageLeft}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          {/*Text Image  Left*/}
          <div className="image-text__left">
            <AnimatePresence exitBeforeEnter>
              {visibility && altTextImageLeft !== "null" && (
                <motion.div
                  variants={
                    visibility && animationSwitcher(textAnimationOptionLeft)
                  }
                  initial="initial"
                  animate="start"
                  exit={{ opacity: 1 }}
                  transition={{
                    duration: textAnimationDurationLeft
                      ? parseInt(textAnimationDurationLeft)
                      : 1.2,
                    delay: textAnimationDelayLeft
                      ? parseInt(textAnimationDelayLeft)
                      : 1.2,
                    ease: easing,
                  }}
                  style={{
                    flexGrow: 1,

                    display: "block",
                  }}
                  className="image-test image2"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      /*               padding:
                      width >= 1920 && height <= 1000 && paddingValueLeft
                        ? paddingValueLeft + "rem"
                        : 0, */
                      display: "block",
                      boxSizing: "border-box",
                    }}
                  >
                    {colLeft.text_image.localFile && (
                      <Img
                        loading="eager"
                        imgStyle={{
                          padding: paddingValueLeft
                            ? `${paddingValueLeft}rem`
                            : "0rem",
                          objectFit: imgFitLeft ? "cover" : "contain",
                          /*    willChange: "transform", */
                          boxSizing: "border-box",
                        }}
                        fadeIn={false}
                        fluid={
                          colLeft.text_image.localFile.childImageSharp.fluid
                        }
                        alt={altTextImageLeft}
                      />
                    )}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>{" "}
          {/*Extra Image  Left*/}
          <div className="image-text__left">
            <AnimatePresence exitBeforeEnter>
              {visibility && altExtraImageLeft !== "null" && (
                <motion.div
                  variants={
                    visibility && animationSwitcher(extraAnimationOptionLeft)
                  }
                  initial="initial"
                  animate="start"
                  exit={{ opacity: 1 }}
                  transition={{
                    duration: extraAnimationDurationLeft
                      ? parseInt(extraAnimationDurationLeft)
                      : 1.2,
                    delay: extraAnimationDelayLeft
                      ? parseInt(extraAnimationDelayLeft)
                      : 1.2,
                    ease: easing,
                  }}
                  style={{
                    flexGrow: 1,

                    display: "block",
                  }}
                  className="image-test image2"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      /*               padding:
                      width >= 1920 && height <= 1000 && paddingValueLeft
                        ? paddingValueLeft + "rem"
                        : 0, */
                      display: "block",
                      boxSizing: "border-box",
                    }}
                  >
                    {imgExtraTextLeftValidation && (
                      <Img
                        loading="eager"
                        imgStyle={{
                          padding: paddingValueLeft
                            ? `${paddingValueLeft}rem`
                            : "0rem",
                          objectFit: imgFitLeft ? "cover" : "contain",
                          /*    willChange: "transform", */
                          boxSizing: "border-box",
                        }}
                        fadeIn={false}
                        fluid={imgExtraTextLeftValidation.fluid}
                        alt={altExtraImageLeft}
                      />
                    )}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      ) : (
        <div
          className="img-container__center"
          style={{
            backgroundColor: colLeft.background_color,
            flexGrow: 1,
          }}
        >
          <AnimatePresence exitBeforeEnter>
            {visibility && (
              <motion.div
                className="wrapper-text__only"
                variants={
                  visibility && animationSwitcher(textAnimationOptionLeft)
                }
                initial="initial"
                animate="start"
                exit={{ opacity: 1 }}
                transition={{
                  duration: textAnimationDurationLeft
                    ? parseInt(textAnimationDurationLeft)
                    : 1.4,
                  delay: textAnimationDelayLeft
                    ? parseInt(textAnimationDelayLeft)
                    : 0.6,

                  ease: easing,
                }}
              >
                {/*Text only */}
                <div
                  style={{ color: textAreaColorLeft }}
                  className="text__only swiper-no-swiping"
                  dangerouslySetInnerHTML={{ __html: textAreaLeft.html }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </>
  );
};

export default SingleColumn;
